import React from "react"
import PartnerCarousel from "../../components/PartnerCarousel"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../../components/Layout"
import { Container, Row, Col } from "react-bootstrap"
import Services from "../../components/Services"
import EducationOffers from "../../components/education/EducationOffers"
import SEO from "../../components/seo"
import SectionTitle from "../../components/SectionTitle"

export default function InteractiveAudiovisual(props) {
  const data = useStaticQuery(graphql`
    query getLogosForInteractiveAudiovisual {
      WirelessNetworks: file(
        relativePath: { eq: "services/AdobeStock_250877262.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      InteractiveTechnology: file(
        relativePath: { eq: "services/AdobeStock_234479733.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      VideoConferencing: file(
        relativePath: { eq: "services/AdobeStock_335250034.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      Maintenance: file(
        relativePath: { eq: "services/AdobeStock_308707407.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      DigitalSignage: file(
        relativePath: { eq: "services/AdobeStock_227933099.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
      SoundSystems: file(
        relativePath: { eq: "services/AdobeStock_184497717.jpeg" }
      ) {
        childImageSharp {
          fixed(height: 280, width: 400) {
            src
            base64
            aspectRatio
            srcSet
            height
            width
          }
        }
      }
    }
  `)

  const services = [
    {
      title: "Wireless Networks",
      description:
        "Access Points, Outdoor Access Points, Wireless Controllers, Routers, Fibre Optic",
      image: data.WirelessNetworks.childImageSharp.fixed,
    },
    {
      title: "Interactive Technology",
      description:
        "Interactive Touchscreens, Interactive Whiteboards, Interactive Projectors, Virtual Reality, Visualisers",
      image: data.InteractiveTechnology.childImageSharp.fixed,
    },
    {
      title: "Digital Signage",
      description: "Display Screens, Outdoor Display Screens, Video Walls",
      image: data.DigitalSignage.childImageSharp.fixed,
    },
    {
      title: "Sound Systems",
      description: "Soundfield, PA Systems, Speakers, Amps, Hall Audio System",
      image: data.SoundSystems.childImageSharp.fixed,
    },
    {
      title: "Video Conferencing",
      description: "Laptops, Webcams, Microphones, Speakerphones",
      image: data.VideoConferencing.childImageSharp.fixed,
    },
    {
      title: "Maintenance",
      description:
        "Extended Warranties, Telephone Support, Remote Support, Onsite Support, Installation",
      image: data.Maintenance.childImageSharp.fixed,
    },
  ]

  return (
    <Layout headerType="education">
      <SEO title="Compulease · Interactive Audiovisual Education" />
      <EducationOffers />
      <Container>
        <Row>
          <Col>
            <SectionTitle
              subtitle="Compulease™ supplies products from the leading audiovisual and
                  interactive technology brands"
              title="Interactive and Audiovisual Solutions"
              description={
                <>
                  <p>
                    Transform your classroom into an exciting, dynamic,
                    interactive and inspirational environment with an
                    interactive touchscreen or other AV solutions.
                  </p>
                  <p>
                    Linked to a computer, Chromebook, laptop or tablet, an
                    interactive touchscreen can help build exciting dynamic and
                    inspirational lessons through its unique suite of software,
                    which is easy to use and fully customisable.
                  </p>
                  <p>
                    The interactive touchscreen combines the simplicity of a
                    whiteboard with the power of a computer. The multi-touch
                    display connects your classroom's IT to your students. You
                    can also control computer applications directly from the
                    display, write notes in digital ink and save your work to
                    share later.
                  </p>
                </>
              }
            />
          </Col>
        </Row>
        <Services title="Solutions we provide" services={services} />
      </Container>
      <PartnerCarousel
        type="educationAudiovisual"
        description="Compulease™ supplies products from the leading audiovisual and
              interactive technology brands."
      />
    </Layout>
  )
}
